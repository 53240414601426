import { MetaFunction } from "@remix-run/react";
import { organizationSchema } from "~/constants/meta";
import { Hero } from "~/routes/_index/Hero";
import { Team } from "~/routes/_index/Team";
import { Vibes } from "~/routes/_index/Vibes";
import { Kontakt } from "~/routes/_index/Kontakt";
import { Region } from "~/routes/_index/Region";
import { Shop } from "~/routes/_index/Shop";
import { Drinks } from "~/routes/_index/Drinks";
import { ImageShow } from "~/routes/_index/ImageShow";

export const meta: MetaFunction = () => {
  return [
    organizationSchema,
    {
      title: "Voll Weinschorle – Erfrischend, spritzig und aus der Dose",
    },
    {
      name: "description",
      content:
        "🍇 Voll Weinschorle - Erlebe ausgewählten deutschen Wein und prickelndes Mineralwasser als perfektes Zusammenspiel. Gemacht für besondere und gemeinsame Momente.",
    },
    {
      property: "og:title",
      content:
        "🍇 Voll Weinschorle – Erfrischend, spritzig und aus der Dose 💦",
    },
    {
      property: "og:image",
      content: "https://updrinks.de/ogimage.png",
    },
  ];
};

export default function Index() {
  return (
    <div className="overflow-x-clip">
      <Hero />
      <Vibes />
      <Drinks />
      <Region />
      <Team />
      {/*<ImageShow />*/}
      <Shop />
      <Kontakt />
    </div>
  );
}
